<template>
<div>
<large-menubar title="Check-In" bar-height="large"
parent-title="PhoneUster" parent-url="https://phoneuster.ch"></large-menubar>
<msg
title="Sie sind nicht eingeloggt"
description="Benutzen Sie den Link aus der E-Mail oder dem Kundenbereich um fortzufahren"
icon="info"
:buttons="buttons"
v-if="!token"
>
</msg>
<msg
title="Kein Auftrag angegeben"
description="Benutzen Sie einen Direktlink oder geben Sie Ihre Auftragsnummer ein"
icon="waiting"
:buttons="buttons"
v-else-if="token"
>
</msg>
</div>
</template>

<script>
import Vue from 'vue'
import {mapGetters, mapState} from 'vuex'
import Msg from 'vux/src/components/msg/index.vue'
import ConfirmPlugin from 'vux/src/plugins/confirm/index.js'

import LargeMenubar from '../components/LargeMenubar'

Vue.use(ConfirmPlugin)

export default {
components: {
LargeMenubar,
Msg
},
data () {
return {




}
},
computed: {
buttons: (t) =>
[
...(t.accessKey ? [{
text: 'Auftrag #' + t.accessKey.toString(),
link: {name: 'Home', id: t.accessKey},
type: 'primary'
}] : []),
{
text: 'Kundenbereich',
link: 'https://phoneuster.ch/my',
type: t.accessKey ? 'default' : 'primary'
},
...(t.token ? [{
text: 'Auftragsnummer eingeben',
onClick: () => t.$vux.confirm.prompt('Auftragsnummer', {
'title': 'Auftragsnummer',
'confirmText': 'Weiter',
'cancelText': 'Abbrechen',
'show-input': true,
onConfirm: (val) => val && t.$router.push({name: 'Home', params: {id: val}}).catch(() => 1)
}),
type: 'default'
}] : [])
],
...mapState({
hasData: 'hasData',
accessKey: 'accessKey',
token: 'token'

}),
...mapGetters([
'customerName'

])
},
filters: {
},
methods: {
},
mounted () {
},
created () {

}
}
</script>

<style lang="scss">

</style>
