// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import FastClick from 'fastclick'
import './registerServiceWorker'
import App from './App'
import store from './store/index'
import router from './router/index'
import {Communication} from './communication.ts'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faWhatsappSquare } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

function communicationFactory () {
  var com = new Communication('wss://iphoneuster.ch:7201')
  // eslint-disable-next-line no-unused-vars
  com.addTypeHandler('data-update', (data, _) => {
    // TODO be smart and compare old_values to current state. if no match, do refresh
    // 'old_values'
    store.commit('updateDataset', [data.dataset, data.new_values, data.keyAttribute, data.keyValue])
  })
  com.ready = () => store.state.token && store.state.accessKey && store.state.com.promiseCmd('setIdentity', {'id': store.state.accessKey, 'token': store.state.token})
  return com
}
store.state.com = communicationFactory()
if (localStorage && localStorage.token) {
  store.commit('setToken', localStorage.token)
}
FastClick.attach(document.body)

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 1) // hide navigation
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = typeof nearestWithTitle.meta.title === 'function' ? nearestWithTitle.meta.title(to) : nearestWithTitle.meta.title

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, typeof tagDef[key] === 'function' ? tagDef[key](to) : tagDef[key])
    })

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

library.add(faWhatsappSquare)

Vue.component('font-awesome-icon', FontAwesomeIcon)

/* eslint-disable no-new */
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app-box')
