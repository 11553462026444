var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasData)?_c('div',[_c('large-menubar',{attrs:{"title":"Kontakt","parent-title":_vm.parentTitle,"bar-height":"small"}}),_c('Callout',{attrs:{"header":"So erreichen Sie uns direkt","footer":"Unkompliziert unter der Woche 08-20 Uhr oder Samstags 10-14 Uhr"}},[_c('div',{attrs:{"id":"messageGroup"}},[_c('div',{staticClass:"icon"},[_c('a',{attrs:{"href":"tel:+41445205285"}},[_c('TelephoneIcon')],1)]),_c('svg',{staticClass:"separator",attrs:{"viewBox":"0 0 1 24.749"}},[_c('path',{attrs:{"fill":"rgba(0,0,0,0)","stroke":"rgba(112,112,112,1)","stroke-width":"1px","stroke-linejoin":"miter","stroke-linecap":"butt","stroke-miterlimit":"4","shape-rendering":"auto","id":"Path_1","d":"M 0 0 L 0 24.748779296875"}})]),_c('div',{staticClass:"icon"},[_c('a',{attrs:{"href":'https://wa.me/41445205285?text=' +
        encodeURIComponent('Hallo, eine Frage:\r\n\r\nMfG '+ _vm.customerName)}},[_c('font-awesome-icon',{attrs:{"icon":['fab', 'whatsapp-square']}})],1)]),_c('svg',{staticClass:"separator",attrs:{"viewBox":"0 0 1 24.749"}},[_c('path',{attrs:{"fill":"rgba(0,0,0,0)","stroke":"rgba(112,112,112,1)","stroke-width":"1px","stroke-linejoin":"miter","stroke-linecap":"butt","stroke-miterlimit":"4","shape-rendering":"auto","id":"Path_2","d":"M 0 0 L 0 24.748779296875"}})]),_c('div',{staticClass:"icon"},[_c('a',{attrs:{"href":'mailto:info@phoneuster.ch?subject=Terminanfrage%20Auftrag%20#' + _vm.order.order_id + '&body='+
        encodeURIComponent('Guten Tag\r\n\r\nIch habe eine Frage betreffend Auftrag #' + _vm.order.order_id + '\r\n\r\nMit freundlichen Grüssen\r\n' + _vm.customerName)
  .replace(/!/g, '%21')
  .replace(/'/g, '%27')
  .replace(/\(/g, '%28')
  .replace(/\)/g, '%29')
  .replace(/\*/g, '%2A')}},[_c('MailIcon')],1)])])]),_c('group',{attrs:{"title":"Standorte"}},_vm._l((_vm.storeLocations),function(storeLocation){return _c('cell',{key:storeLocation.name,staticClass:"oversize",attrs:{"title":storeLocation.name,"inline-desc":storeLocation.street + ', ' + storeLocation.zip + ' ' + storeLocation.city}},[_c('img',{attrs:{"width":"70px","height":"70px","src":'https://maps.googleapis.com/maps/api/staticmap?zoom=11&size=70x70&scale=2&maptype=roadmap&markers='+
        encodeURIComponent('size:tiny|'+storeLocation.street + ', ' + storeLocation.zip + ' ' + storeLocation.city)+
        '&key='+_vm.$store.state.mapsApiKey}})])}),1),_c('group',[_c('div',{attrs:{"id":"storeMap"}})])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }