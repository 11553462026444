import Vue from 'vue'
import Router from 'vue-router'
import HomeView from '../views/HomeView'
import AddressView from '../views/AddressView'
import ProfileView from '../views/ProfileView'
import AppointmentsView from '../views/AppointmentsView'
import store from '../store/index'
import { ToastPlugin, LoadingPlugin } from 'vux'
import LockScreenView from '../views/LockScreenView'

Vue.use(LoadingPlugin)
Vue.use(ToastPlugin)
Vue.use(Router)

let vueProxy = (cb) => cb(Vue)

function handleBeforeEnter (to, from, next) {
  if (!store.state.token) {
    next({name: 'LockScreen'})
  } else if ('id' in to.params || !store.state.hasData) {
    vueProxy((Vue) => Vue.$vux.loading.show({
      text: 'Laden...'
    }))
    let errorHandler = (reason) => {
      vueProxy((Vue) => {
        Vue.$vux.loading.hide()
        if (reason.error === 'Invalid token') {
          reason.error = 'Zugriff verweigert'
        } else if (reason.error === 'Invalid state') {
          reason.error = 'Etwas ging schief'
        }
        Vue.$vux.toast.show({
          text: reason.error,
          type: 'warn'
        })
      }
      )
      next(false)
    }
    // eslint-disable-next-line no-unused-vars
    let refreshAction = () => vueProxy((Vue) => store.dispatch('refresh', [['order', 'customer', 'positions', 'services', 'devices', 'appointments', 'addresses'], 60000]).then(
      () => {
        vueProxy((Vue) => Vue.$vux.loading.hide())
        next()
      },
      errorHandler
    ))
    // eslint-disable-next-line eqeqeq
    if (store.state.accessKey == to.params.id) {
      refreshAction()
    } else {
      // eslint-disable-next-line no-unused-vars
      vueProxy((Vue) => store.dispatch('setIdentity', to.params.id).then(refreshAction, errorHandler))
    }
  } else {
    next()
  }
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'LockScreen',
      component: LockScreenView,
      meta: {
        title: 'Check-in | PhoneUster'
      }
    },
    {
      path: '/profil',
      name: 'Profile',
      component: ProfileView,
      beforeEnter: handleBeforeEnter,
      meta: {
        title: 'Mein Profil'
      }
    },
    {
      path: '/:id/termine',
      name: 'Appointments',
      component: AppointmentsView,
      beforeEnter: handleBeforeEnter,
      meta: {
        title: (r) => 'Termine Auftrag #' + r.params.id.toString()
      }
    },
    {
      path: '/:id/kontakt',
      name: 'Contact',
      component: AddressView,
      beforeEnter: handleBeforeEnter,
      meta: {
        title: 'Kontakt'
      }
    },
    {
      path: '/:token/:id',
      redirect: to => {
        store.commit('setToken', to.params.token)
        return '/:id'
      },
      meta: {
        title: 'Check-in öffnen'
      }
    },
    {
      path: '/:id',
      name: 'Home',
      component: HomeView,
      beforeEnter: handleBeforeEnter,
      meta: {
        title: (r) => 'Auftrag #' + r.params.id.toString()
      }
    }
  ]
})
