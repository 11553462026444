<template>
<div v-if="hasData">
<large-menubar title="Kontakt" :parent-title="parentTitle" bar-height="small"></large-menubar>

<Callout
header="So erreichen Sie uns direkt"
footer="Unkompliziert unter der Woche 08-20 Uhr oder Samstags 10-14 Uhr">
<div id="messageGroup">
<div class="icon">
<a href="tel:+41445205285"><TelephoneIcon/></a>
</div>
<svg class="separator" viewBox="0 0 1 24.749">
<path fill="rgba(0,0,0,0)" stroke="rgba(112,112,112,1)" stroke-width="1px" stroke-linejoin="miter"
stroke-linecap="butt" stroke-miterlimit="4" shape-rendering="auto" id="Path_1"
d="M 0 0 L 0 24.748779296875">
</path>
</svg>
<div class="icon">
<a :href="'https://wa.me/41445205285?text=' +
encodeURIComponent('Hallo, eine Frage:\r\n\r\nMfG '+ customerName)">
<font-awesome-icon :icon="['fab', 'whatsapp-square']"/>
</a>
</div>
<svg class="separator" viewBox="0 0 1 24.749">
<path fill="rgba(0,0,0,0)" stroke="rgba(112,112,112,1)" stroke-width="1px" stroke-linejoin="miter"
stroke-linecap="butt" stroke-miterlimit="4" shape-rendering="auto" id="Path_2"
d="M 0 0 L 0 24.748779296875">
</path>
</svg>
<div class="icon">
<a :href="'mailto:info@phoneuster.ch?subject=Terminanfrage%20Auftrag%20#' + order.order_id + '&body='+
encodeURIComponent('Guten Tag\r\n\r\nIch habe eine Frage betreffend Auftrag #' + order.order_id + '\r\n\r\nMit freundlichen Grüssen\r\n' + customerName)
.replace(/!/g, '%21')
.replace(/'/g, '%27')
.replace(/\(/g, '%28')
.replace(/\)/g, '%29')
.replace(/\*/g, '%2A')"><MailIcon/></a>
</div>
</div>
</Callout>

<group title="Standorte">
<cell v-for="storeLocation in storeLocations" :key="storeLocation.name" class="oversize"
:title="storeLocation.name" :inline-desc="storeLocation.street + ', ' + storeLocation.zip + ' ' + storeLocation.city">
<img width="70px" height="70px" :src="'https://maps.googleapis.com/maps/api/staticmap?zoom=11&size=70x70&scale=2&maptype=roadmap&markers='+
encodeURIComponent('size:tiny|'+storeLocation.street + ', ' + storeLocation.zip + ' ' + storeLocation.city)+
'&key='+$store.state.mapsApiKey"/>
</cell>
</group>

<group>
<div id="storeMap"></div>
</group>
</div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import Cell from 'vux/src/components/cell/index.vue'
import Group from 'vux/src/components/group/index.vue'

import LargeMenubar from '../components/LargeMenubar'
import Callout from '../components/Callout'
import {DateTime} from 'luxon'
import {serviceImageLinks} from '../store'
import TelephoneIcon from '../../static/icon/SVG/call.svg'
import MailIcon from '../../static/icon/SVG/email.svg'

let dtf = new Intl.DateTimeFormat('de-CH');
let longDateTimeFormat
try {
longDateTimeFormat = new Intl.DateTimeFormat('de-ch', {
dateStyle: 'medium',
timeStyle: 'short',
month: '2-digit'
})
} catch (e) {
longDateTimeFormat = new Intl.DateTimeFormat('de-ch', {
month: '2-digit'
})
}

export default {
components: {
Callout,
LargeMenubar,
Group,
Cell,
TelephoneIcon,
MailIcon
},
data () {
return {




}
},
computed: {
parentTitle: (t) => 'Auftrag #' + t.order.order_id,
...mapState({
hasData: 'hasData',
order: 'order',
appointments: 'appointments',
storeLocations: 'storeLocations'

}),
...mapGetters([
'customerName',
'getDeviceImageThumbnailById',
'getPositionTitleByPosition',
'getPositionSubtitleByPosition',
'getClosestAppointmentCond'

]),
closestAppointmentPreRepair: (t) => t.getClosestAppointmentCond(apt => apt.type === t.APPOINTMENT_TYPE_BRING_IN || apt.type === t.APPOINTMENT_TYPE_ON_SITE || apt.type === t.APPOINTMENT_TYPE_DROP_OFF || apt.type === t.APPOINTMENT_TYPE_FETCH),
closestAppointmentDuringRepair: (t) => t.getClosestAppointmentCond(apt => apt.type === t.APPOINTMENT_TYPE_PLANNED_REPAIR || apt.type === t.APPOINTMENT_TYPE_ON_SITE),
closestAppointmentAfterRepair: (t) => t.getClosestAppointmentCond(apt => apt.type === t.APPOINTMENT_TYPE_RETRIEVE || apt.type === t.APPOINTMENT_TYPE_DELIVER),
closestAppointmentSendBack: (t) => t.getClosestAppointmentCond(apt => apt.type === t.APPOINTMENT_TYPE_SEND_BACK),
proposedDates: () => '',
getPositionImage: (t) => (position) => {
if (position.service_fid in serviceImageLinks) return serviceImageLinks[position.service_fid][1]
let realDeviceFid = t.getServiceById(position.service_fid).real_device_fid
return t.getDeviceImageThumbnailById(realDeviceFid) || serviceImageLinks[0][1]
}
},
filters: {
currency: (val) => val === null ? 'N/A' : val === 0 ? 'kostenlos' : ('CHF ' + (Math.round(val * 100) % 100 === 0 ? (Math.round(val * 100) / 100) : (Math.round(val * 100) / 100).toFixed(2))),

twoLineDateTime: (val) => val === 0 ? '' : longDateTimeFormat.formatToParts(new Date(val)).map(({type, value}) => {
switch (value) {
case ', ':
return '\n'
default :
return value
}
}).reduce((string, part) => string + part),
duration: (millis) => dtf.format(new Date(millis)),
fromNow: (millitime) => DateTime.fromMillis(millitime).setLocale('de-ch').toRelative(),
},
mounted () {
},
created () {
this.ORDER_STATUS_PROCESSING = 0
this.ORDER_STATUS_PENDING_PRE_PAYMENT = 1
this.ORDER_STATUS_WAITING_FOR_PART = 2
this.ORDER_STATUS_WAITING_FOR_DEVICE = 3
this.ORDER_STATUS_PENDING_REPAIR = 4
this.ORDER_STATUS_PENDING_PRE_RETURN_PAYMENT = 5
this.ORDER_STATUS_PENDING_RETURN_DLVR = 6
this.ORDER_STATUS_PENDING_RETURN = 7
this.ORDER_STATUS_PENDING_RETURN_FETCH = 8
this.ORDER_STATUS_PENDING_POST_PAYMENT = 9
this.ORDER_STATUS_COMPLETED = 10

this.APPOINTMENT_TYPE_ON_SITE = 'on-site'
this.APPOINTMENT_TYPE_BRING_IN = 'bring-in'
this.APPOINTMENT_TYPE_PLANNED_REPAIR = 'planned-repair'
this.APPOINTMENT_TYPE_DROP_OFF = 'drop-off'
this.APPOINTMENT_TYPE_RETRIEVE = 'retrieve'
this.APPOINTMENT_TYPE_FETCH = 'fetch'
this.APPOINTMENT_TYPE_DELIVER = 'deliver'
this.APPOINTMENT_TYPE_SEND_IN = 'send-in'
this.APPOINTMENT_TYPE_SEND_BACK = 'send-back'
}
}
</script>

<style lang="scss">

#messageGroup {
width: 80vw;
max-width: 300px;
height: 54px;
margin: 0 auto;
display: flex;
justify-content: space-between;

.separator {
overflow: visible;
width: 1px;
height: 24.749px;
margin: 15.126px 0;
transform: matrix(1, 0, 0, 1, 0, 0);
}

.icon {
white-space: nowrap;
text-align: center;
font-family: -apple-system, "SF Pro Text",
BlinkMacSystemFont,
"Segoe UI",
Roboto,
Helvetica,
Arial,
sans-serif,
"Apple Color Emoji",  /* Emojis*/
"Segoe UI Emoji", /* Emojis*/
"Segoe UI Symbol"; /* Emojis*/;
font-style: normal;
font-weight: normal;
font-size: 45px;
color: rgba(112, 112, 112, 1);
svg {
height: 54px;
&.fa-whatsapp-square {
width: unset;
height: 62px;
margin-top: -4px;
fill: #707070;
& > path {
fill: #707070;
}
}
}
}
}
</style>
