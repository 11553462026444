<template>
<div v-if="hasData">
<large-menubar title="Termine & Nachrichten" :parent-title="parentTitle" bar-height="small"></large-menubar>
<div v-for="appointment in getAppointmentsByStart" :key="appointment.appointment_id">
<divider>{{new Date(appointment.start*1000).toLocaleString()}}</divider>
<group-title>{{$options.filters.appointmentType(appointment.type)}}</group-title>
<card>
<template v-slot:content>
<div class="card-inner">
<cell v-if="appointment.customer_notes" title="Anmerkung">
<template v-slot:inline-desc>
<em style="white-space: pre-line">{{ appointment.customer_notes }}</em>
</template>
</cell>
<cell title="Adresse"
:link="'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(getAddressById(appointment.address_fid) ? formatGmapsAddress(getAddressById(appointment.address_fid)) : (((l) => l.street + ', ' + l.zip + ' ' + l.city)(storeLocations[0])))">
<template v-slot:inline-desc v-if="appointment.address_fid">
<div>{{ getAddressById(appointment.address_fid).firstname || customer.vorname }}
{{ getAddressById(appointment.address_fid).lastname || customer.nachname }}
</div>
<div v-if="getAddressById(appointment.address_fid).company">
{{ getAddressById(appointment.address_fid).company }}
</div>
<div v-if="getAddressById(appointment.address_fid).street">
{{ getAddressById(appointment.address_fid).street }}
</div>
<div v-if="getAddressById(appointment.address_fid).po_box">
{{ getAddressById(appointment.address_fid).po_box }}
</div>
<div>{{ getAddressById(appointment.address_fid).zip }}
{{ getAddressById(appointment.address_fid).city }}
</div>
</template>
<template v-slot:value v-if="appointment.address_fid">
{{ addressName(getAddressById(appointment.address_fid)) }}
</template>
<template v-else>
PhoneUster
</template>
</cell>
<cell title="Tracking" v-if="appointment.tracking_number" :link="trackingLink(appointment)">
<template v-slot:inline-desc>
{{ appointment.shipping_carrier | shippingCarrier }}: {{ appointment.tracking_number }}
</template>
</cell>
<cell :title="customerName" v-if="appointment.customer_parttype !== 'NON-PARTICIPANT'">
{{ getPartstat(appointment.customer_partstat) }}
<template v-slot:inline-desc v-if="appointment.start*1000 > Date.now()">
<table>
<tr>
<td>
<XButton mini type="primary" :disabled="appointment.customer_partstat === 'ACCEPTED'"
@click.native="updatePartstat([appointment.appointment_id, 'ACCEPTED'])" title="zusagen">
<font-awesome-icon icon="check-circle" style="margin: 0.3em auto;display:block;"/>
</XButton>
</td>
<td>
<XButton mini :disabled="appointment.customer_partstat === 'TENTATIVE'"
@click.native="updatePartstat([appointment.appointment_id, 'TENTATIVE'])"
title="vielleicht">
<font-awesome-icon icon="question-circle" style="margin: 0.3em auto;display:block;"/>
</XButton>
</td>
<td>
<XButton mini type="warn" :disabled="appointment.customer_partstat === 'DECLINED'"
@click.native="updatePartstat([appointment.appointment_id, 'DECLINED'])"
title="ablehnen">
<font-awesome-icon icon="times-circle" style="margin: 0.3em auto;display:block;"/>
</XButton>
</td>
</tr>
</table>
</template>
</cell>
<Datetime title="Neue Zeit vorschlagen"
inline-desc="Bitte kontaktieren Sie uns, wenn Sie keine Bestätigung erhalten"
v-if="appointment.customer_partstat === 'DECLINED' || appointment.customer_partstat === 'TENTATIVE'"
v-model="appointment.customer_time_proposal"
format="YYYY-MM-DD HH:mm" :display-format="(a) => new Date(a).toLocaleString()"
clear-text="Löschen" @on-clear="() => {appointment.customer_time_proposal = null; updateSuggestedTime([appointment.appointment_id, null])}"
:start-date="(new Date()).toJSON().split('T')[0]"
:end-date="(new Date(Date.now()+72*3600*1000)).toJSON().split('T')[0]"
:minute-list="[0,5,10,15,20,25,30,35,40,45,50,55]" :hour-list="[8,9,10,11,12,13,14,15,16,17]"
year-row="{value}" month-row="{value}." day-row="{value}." hour-row="{value}:"
minute-row="{value}" confirm-text="Vorschlagen" cancel-text="Abbruch"
@on-confirm="(st) => updateSuggestedTime([appointment.appointment_id, Math.floor(new Date(appointment.customer_time_proposal).getTime()/1000)])"
:order-map="{
year: 3,
month: 2,
day: 1,
hour: 4,
minute: 5,
}"
/>
<cell title="PhoneUster">
{{ getPartstat(appointment.employee_partstat) }}
</cell>
</div>
</template>
<template v-slot:footer>
<div class="weui-panel__ft">
<cell title="1" class="weui-cell_link">
<template v-slot:title>{{ appointment.start * 1000 | smartRelativeTime }}</template>
Dauer {{ appointment.duration * 1000 | duration }}
</cell>
</div>
</template>
</card>
</div>
<div v-if="appointments.length == 0">
<!-- 65 -->
<group-title>Bring-in</group-title>
<cell v-if="getRepairDuration > 0">Ungefähre Dauer {{getRepairDuration*1000 | duration}}</cell>
<flow>
<flow-state state="1" title="Auftrag" is-done></flow-state>
<flow-line is-done></flow-line>

<flow-state state="2" title="Terminanfrage"></flow-state>
<flow-line></flow-line>

<flow-state state="3" title="Bestätigung"></flow-state>
<flow-line></flow-line>

<flow-state state="4" title="Reparatur"></flow-state>
</flow>
<group-title>Zeitfenster wählen</group-title>
<div v-if="getRepairDuration > 0">
<cell v-if="availability.length == 0" :is-loading="true">Daten abrufen...</cell>
<div v-for="day in availabilityByDay" :key="day.date">
<group :title="day.date">
<cell title="1" v-for="availability in day.availability.filter(a => a[1]-a[0] > getRepairDuration)" :key="availability[0]" @click.native="proposalRange = availability; showProposalWindow = true" :is-link="true">
<template v-slot:title>{{availability[0]*1000 | time}} - {{availability[1]*1000 | time }}</template>
</cell>
</group>
</div>
<x-button v-if="availability.reduce((p, c) => Math.max(p, c.until), 0)*1000 - new Date() < 5*24*3600*1000"
action-type="button"
type="primary"
@click.native="() => {loadingMoreAvailabilities = true; getMoreAvailability([3600*24]).then(() => loadingMoreAvailabilities = false)}"
:show-loading="loadingMoreAvailabilities"
:disabled="loadingMoreAvailabilities">
Weitere laden
</x-button>
</div>
<cell title="Für diesen Auftrag nicht verfügbar. Fragen Sie einen Termin per Mail oder WhatsApp an." v-else>
Benötigte Zeit unbekannt
</cell>

<div v-transfer-dom>
<popup v-model="showProposalWindow">
<cell v-if="proposalRange.length" title="Zeit wählen" primary="content">
<template v-slot:inline-desc>{{ Math.round(proposalChoice/300)*300 * 1000 | dateTime }}</template>
<range :min="Math.ceil(proposalRange[0]/60/5)*5*60" :max="Math.ceil((proposalRange[1]-getRepairDuration)/60/5)*5*60" v-model="proposalChoice"
:step="300" minHTML=" " maxHTML=" "
@on-change="() => {
let dt = new Date(proposalChoice*1000);
(!dayToShow || dayToShow.getDate() != dt.getDate()) ? dayToShow = new Date(dt.setHours(0,0,0,0)):1;
$refs['ScheduleObj'].scrollTo(dt.getHours()-1+':'+Math.floor(dt.getMinutes()/15)*15)
}"></range>
</cell>
<group v-if="proposalRange.length">
<cell :is-link="true" title="Anfrage abschicken" @click.native="onAddAppointmentRequest"></cell>
</group>
<group v-if="proposalRange.length" title="Kalenderansicht" style="height: 60vh; min-height: 200px; overflow:hidden;">
<ejs-schedule id='Schedule' ref="ScheduleObj" height="650px" :cssClass='cssClass'
:eventSettings='eventSettings'
:currentView='currentView' :workHours="workHours" :selectedDate="dayToShow"
:minDate="schedMinLoadedDate" :maxDate="schedMaxLoadedDate"
@resizeStart="cancelEventResize"
@cellClick="onCellClick"
@cellDoubleClick="onCellClick"
@actionBegin="onActionBegin"
@actionComplete="onActionComplete"
>
<e-views>
<e-view option="Day"></e-view>
<e-view option="TimelineDay"></e-view>
</e-views>
</ejs-schedule>
</group>
</popup>
</div>
<!-- 31 -->
<group-title>Postversand</group-title>
<flow>
<flow-state state="1" title="Auftrag" is-done></flow-state>
<flow-line is-done></flow-line>

<flow-state state="2" title="Versand"></flow-state>
<flow-line></flow-line>

<flow-state state="3" title="Reparatur"></flow-state>
<flow-line></flow-line>

<flow-state state="4" title="Rückversand"></flow-state>
</flow>
</div>
</div>
</template>

<script>
import Vue from "vue";
import {mapGetters, mapState, mapActions} from 'vuex'
import Cell from 'vux/src/components/cell/index.vue'
import Card from 'vux/src/components/card/index.vue'
import XButton from 'vux/src/components/x-button/index.vue'
import Datetime from 'vux/src/components/datetime/index.vue'
import Divider from 'vux/src/components/divider/index.vue'
import GroupTitle from 'vux/src/components/group-title/index.vue'
import Flow from 'vux/src/components/flow/flow.vue'
import FlowState from 'vux/src/components/flow/flow-state.vue'
import FlowLine from 'vux/src/components/flow/flow-line.vue'
import Group from 'vux/src/components/group/index.vue'
import Range from 'vux/src/components/range/index.vue'
import TransferDom from 'vux/src/directives/transfer-dom/index.js'
import Popup from 'vux/src/components/popup/index.vue'
import AlertPlugin from 'vux/src/plugins/alert/index.js'

import LargeMenubar from '../components/LargeMenubar'
import {DateTime, Duration} from 'luxon'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faCheckCircle, faTimesCircle, faQuestionCircle} from '@fortawesome/free-solid-svg-icons'
Vue.use(AlertPlugin);


import { SchedulePlugin, Day, TimelineViews, View, Resize, DragAndDrop } from "@syncfusion/ej2-vue-schedule";
Vue.use(SchedulePlugin);
Vue.directive('transfer-dom', TransferDom); 


library.add(faCheckCircle)
library.add(faQuestionCircle)
library.add(faTimesCircle)

export default {
components: {
LargeMenubar,
Card,
Cell,
XButton,
Datetime,
Divider,
GroupTitle,
Flow, FlowState, FlowLine,
Group,
Range,

View,
Popup
},
data() {
return {




showProposalWindow: false,
proposalRange: [],
proposalChoice: 0,
dayToShow: 0,
workHours: {
highlight: true,
start: '08:00',
end: '19:00'
},
currentView: 'Day',
cssClass: 'block-events',
loadingMoreAvailabilities: false,
}
},
computed: {
availabilityByDay: (t) => Object.entries(t.availability.reduce((r, itm) => {
itm.availability.forEach((a) => {
let dt = new Date(new Date(a[0] * 1000).setHours(0,0,0,0));
r[dt.getTime()] = r[dt.getTime()] || {timestamp: dt, date:dt.toLocaleDateString(), availability: []};
r[dt.getTime()].availability.push(a);
});
return r;
}, Object.create(null)) || {}).sort(([a,],[b,]) => a-b).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}),
schedMinLoadedDate: (t) => new Date(t.availability.reduce((o,n) => Math.min(o,n.from),new Date().getTime()/1000)*1000),
schedMaxLoadedDate: (t) => new Date(t.availability.reduce((o,n) => Math.max(o,n.until),(new Date().setHours(0,0,0,0))/1000+3600*24)*1000),
eventSettings: (t) => ({
dataSource: t.schedulerData
}),
nextFreeSlot: (t) => t.availability.reduce((min, {availability}) => availability.filter(([l,u]) => u-l >= t.getRepairDuration*1000).reduce((p,[n]) => Math.min(p,n),min), new Date(2099,1,1).getTime()),
schedulerData: (t) => [
{
Id: "newAppointment",
Subject: "Terminvorschlag",
StartTime: new Date((t.proposalChoice > 1000 ? Math.round(t.proposalChoice/300)*300 : t.nextFreeSlot)*1000),
EndTime: new Date(((t.proposalChoice > 1000 ? Math.round(t.proposalChoice/300)*300 : t.nextFreeSlot)+t.getRepairDuration)*1000),
Location: "PhoneUster",
IsReadonly: true
},
...t.getDataBlocks.reduce(
(e, availabilities, i1) => [...e, ...availabilities.map(([start, end], i2) => ({
Id: i1.toString()+"_"+i2.toString(),
Subject: 'ausgebucht',
StartTime: new Date(start*1000),
EndTime: new Date(end*1000),
IsBlock: true
}))]
,[])],
getDataBlocks: (t) => t.availability.map(
({from, until, availability}) => availability.reduce(
(prev, [start, end]) => prev.reduce(
(prev, [from, to]) => from < end && to > start ?
[...prev, [from, Math.max(from, start)], [Math.max(from, end), to]] : [...prev, [from, to]]
,[])
,[[from, until]]
).filter(([a,b]) => b > a)
),
parentTitle: (t) => 'Auftrag #' + t.order.order_id,
trackingLink: () => (appointment) => {
switch (appointment.shipping_carrier) {
case 'POSTCH':
return 'https://www.post.ch/track?formattedparcelcodes=' + appointment.tracking_number
case 'DPDCH':
return 'https://tracking.dpd.de/status/de_CH/parcel/' + appointment.tracking_number
case 'DHLEX':
return 'https://www.dhl.com/ch-de/home/tracking.html?tracking-id=' + appointment.tracking_number
default:
return ''
}
},
...mapState({
hasData: 'hasData',
order: 'order',
customer: 'customer',
customerAddresses: 'addresses',
appointments: 'appointments',
storeLocations: 'storeLocations',
availability: 'availability',

}),
...mapGetters([
'customerName',
'getAddressById',
'getRepairDuration',
'getPartstat',
'getParttype',
'getAppointmentsByStart'

])
},
filters: {
appointmentType: (type) => {
return ({
'bring-in': 'Reparaturtermin bei PhoneUster',
'planned-repair': 'Reparatur-Zeitfenster',
'drop-off': 'Abgabetermin bei PhoneUster',
'retrieve': 'Abholtermin bei PhoneUster',
'fetch': 'Abholtermin (bei Ihnen)',
'deliver': 'Rückgabetermin (bei Ihnen)',
'on-site': 'Reparaturtermin (bei Ihnen)',
'send-in': 'Einsendetermin',
'send-back': 'Rückversand'
})[type] || 'Termin'
},
shippingCarrier: (carrier) => {
switch (carrier) {
case 'POSTCH':
return 'Die Post'
case 'DPDCH':
return 'DPD Schweiz'
case 'DHLEX':
return 'DHL Express'
default:
return ''
}
},
duration: (millis) => Duration.fromMillis(millis).toFormat('hh:mm'),
fromNow: (millitime) => DateTime.fromMillis(millitime).setLocale('de-ch').toRelative(),
dateTime: (millitime) => DateTime.fromMillis(millitime).setLocale('de-ch').toLocaleString(DateTime.DATETIME_SHORT),
time: (millitime) => DateTime.fromMillis(millitime).setLocale('de-ch').toLocaleString(DateTime.TIME_SIMPLE),
smartRelativeTime: (millitime) => (Math.abs(Date.now() - millitime < 3600 * 12) ? DateTime.fromMillis(millitime).setLocale('de-ch').toRelative() : DateTime.fromMillis(millitime).setLocale('de-ch').toLocaleString(DateTime.DATETIME_SHORT))
},
methods: {
addressName(address) {
let typemap = {
'default': 'Standard',
'business': 'Geschäft'
}
return address.address_name || typemap[address.type] || 'Adresse'
},
formatGmapsAddress: (customerAddress) => customerAddress.street + ', ' + customerAddress.zip + ' ' + customerAddress.city,
...mapActions([
'updatePartstat',
'updateSuggestedTime',
'getAvailability',
'getMoreAvailability',
'addAppointmentRequest',
]),
onDragStart: function (args) {
let {name, event/*, cancel*/} = args;
console.log([name, args]);
args.cancel =  (event.Id === 'newAppointment');
},
cancelEventResize: function (args) {
let {data/*, cancel*/} = args;
args.cancel = data.Id === 'newAppointment'
},
onCellClick: function (args) {
let {name, startTime/*, cancel*/} = args;
console.log([name, startTime]);
if (name === 'cellClick' || name === 'cellDoubleClick'){
let choice = Math.floor(startTime.getTime()/1000)
if (this.proposalRange[0] <= choice && this.proposalRange[1] >= choice + this.getRepairDuration){
this.proposalChoice = choice;
} else {
let slots = this.availability.filter(({from, until, availability}) => from <= choice && until >= choice + this.getRepairDuration && availability.find(([from, until]) => from <= choice && until >= choice + this.getRepairDuration) != null)
if (slots.length > 0) {
this.proposalChoice = choice;
this.proposalRange = slots[0].availability.find(([from, until]) => from <= choice && until >= choice + this.getRepairDuration)
} else {
console.warn("Out of scope")
}
}
}

args.cancel = true;
},
onActionBegin: function (args) {
let {requestType, data, items} = args
console.log(requestType, "begin");
if (requestType === 'eventCreate' && data.length > 0) {
let eventData = data[0];
let scheduleObj = this.$refs.scheduleObj.ej2Instances;
let eventField = scheduleObj.eventFields;
let startDate = eventData[eventField.startTime];
let endDate = eventData[eventField.endTime];

args.cancel = !scheduleObj.isSlotAvailable(startDate, endDate);
} else if (requestType === 'toolbarItemRendering') {

args.items = items.filter((x) =>  x.prefixIcon !== 'e-icon-add')
}
},onActionComplete: function ({requestType}) {
console.log(requestType, "complete")
if ( requestType === "viewNavigate" || requestType === "dateNavigate") {
var currentViewDates = this.$refs['ScheduleObj'].getCurrentViewDates();
var startDate = currentViewDates[0];
var endDate = currentViewDates[currentViewDates.length - 1];
if (this.dayToShow > endDate || this.dayToShow < startDate) this.dayToShow = startDate;
let startTimestamp = Math.floor(startDate.getTime()/1000), endTimestamp = Math.floor(endDate.getTime()/1000)+24*3600, slots = this.availability.filter(({from, until, availability}) => from <= endTimestamp && until >= startTimestamp && availability.find(([from, until]) => from <= endTimestamp && until >= startTimestamp) != null)
console.log(startTimestamp, endTimestamp, slots);
if (slots.length > 0) {
let avail = slots[0].availability.find(([from, until]) => from <= endTimestamp && until >= startTimestamp)
this.proposalRange = avail
this.proposalChoice = avail[0]
}
}
},
onAddAppointmentRequest: function () {
this.$vux.loading.show({
text: 'Termin wird angefragt...'
})
this.addAppointmentRequest([Math.round(this.proposalChoice/300)*300, this.getRepairDuration]).then(
() => {
this.showProposalWindow = false;
this.$vux.loading.hide()
this.$vux.alert.show({
title: 'Termin angefragt',
content: 'Ihre Anfrage wurde eingereicht. Beachten Sie, dass wir alle Termine immer definitiv per E-Mail bestätigen.\n' +
'Erst mit der Bestätigung per Mail ist ihr Termin definitiv.\n' +
'Eilig? Bitte kontaktieren Sie uns per Telefon / E-Mail.',
buttonText: 'Alles klar'
});
},
() => {
this.$vux.alert.show({
title: 'Fehler',
content: 'Die Anfrage konnte nicht abgeschlossen werden. Bitte kontaktieren Sie uns per Telefon / E-Mail.',
buttonText: 'OK'
});
}
)
}
},
mounted() {
this.getAvailability()
},
created() {
this.APPOINTMENT_TYPE_ON_SITE = 'on-site'
this.APPOINTMENT_TYPE_BRING_IN = 'bring-in'
this.APPOINTMENT_TYPE_PLANNED_REPAIR = 'planned-repair'
this.APPOINTMENT_TYPE_DROP_OFF = 'drop-off'
this.APPOINTMENT_TYPE_RETRIEVE = 'retrieve'
this.APPOINTMENT_TYPE_FETCH = 'fetch'
this.APPOINTMENT_TYPE_DELIVER = 'deliver'
this.APPOINTMENT_TYPE_SEND_IN = 'send-in'
this.APPOINTMENT_TYPE_SEND_BACK = 'send-back'
},
provide: {
schedule: [Day, TimelineViews, Resize, DragAndDrop]
}
}
</script>
<style lang="scss">
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css';

.profile-header {
padding: 10px 0 10px;
display: grid;
grid-template-rows: auto 34px;
grid-template-columns: 1fr 1fr 1fr;
grid-row-gap: 15px;

.profile-name {
white-space: nowrap;
line-height: 34px;
text-align: center;
font-family: -apple-system, "SF Pro Display",
BlinkMacSystemFont,
"Segoe UI",
Roboto,
Helvetica,
Arial,
sans-serif,
"Apple Color Emoji", /* Emojis*/
"Segoe UI Emoji", /* Emojis*/
"Segoe UI Symbol"; /* Emojis*/
;
font-style: normal;
font-weight: normal;
font-size: 28px;
color: #000000;
grid-column: 2;
grid-row: 2;
}

.profile-image {
width: 80px;
height: 80px;
margin: 0 auto;
grid-column: 2;
grid-row: 1;

.initials {
white-space: nowrap;
font-family: -apple-system, "SF Pro Text",
BlinkMacSystemFont,
"Segoe UI",
Roboto,
Helvetica,
Arial,
sans-serif,
"Apple Color Emoji", /* Emojis*/
"Segoe UI Emoji", /* Emojis*/
"Segoe UI Symbol"; /* Emojis*/
;
font-style: normal;
font-weight: normal;
font-size: 28px;
fill: #ffffff;
dominant-baseline: central;
}
}
}

.weui-dialog {
.weui-dialog__bd {
word-break: break-word;
hyphens: auto;
}
}
</style>
