<template>
    <div class="callout">
        <div class="header-text">
            <slot name="header">{{header}}</slot>
        </div>
        <div class="body">
          <slot></slot>
        </div>
        <div class="footer-text">
          <slot name="footer">{{footer}}</slot>
        </div>
    </div>
</template>
<script>
    export default {
      props: {
        header: String,
        footer: String
      }
    }
</script>
<style lang="scss">

    .callout {
        padding: 20px 0;
        position: relative;
        text-align: center;
        font-family: -apple-system, "SF Pro Text",
                      BlinkMacSystemFont,
                      "Segoe UI",
                      Roboto,
                      Helvetica,
                      Arial,
                      sans-serif,
                      "Apple Color Emoji",  /* Emojis*/
                      "Segoe UI Emoji", /* Emojis*/
                      "Segoe UI Symbol"; /* Emojis*/
        font-style: normal;
        color: rgba(0, 0, 0, 1);

      .footer-text {
        line-height: 20px;
        font-size: 15px;
        letter-spacing: -0.26px;
      }
      .body {
        line-height: 1.2;
        padding: 11px 0;
        font-weight: bold;
        font-size: 33px;
        letter-spacing: -0.24px;
      }
      .header-text {
        line-height: 22px;
        font-size: 17px;
        letter-spacing: -0.25px;
      }
    }
</style>
