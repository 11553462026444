/* eslint-disable no-unused-vars */

type ResponseCallback = (response: any, handler:ResponseHandlerInfo) => void;
type ResponseHandlerInfo = {type: string, callback: ResponseCallback}
export class Communication {
  private server:string;
  private ws: WebSocket | undefined;
  private callbacks = new Map<number, ResponseHandlerInfo>();
  private typeHandlers = new Map<string, ResponseHandlerInfo[]>();
  private cbno = Math.floor(Math.random() * 1000);
  public ready: (() => void) | undefined;

  protected sendCmd(cmd:string, callback:ResponseCallback, additional:any = {}) {
    if (this.ws === undefined) return;
    if (this.ws.readyState === WebSocket.CLOSED){
      this.initWebsocket();
      setTimeout(() => this.sendCmd(cmd, callback, additional), 1000)
    } else if (this.ws.readyState !== WebSocket.OPEN){
      setTimeout(() => this.sendCmd(cmd, callback, additional), 1000)
    } else {
      additional["seq"] = this.cbno++;
      this.callbacks.set(additional.seq, {type: cmd, callback: callback});
      additional["type"] = cmd;
      this.ws.send(JSON.stringify(additional));
    }
  }

  private messageHandler(event: MessageEvent<any>) {
    if (this.ws === undefined) return;
    try {
      let data = JSON.parse(event.data);
      if ("type" in data && data.type === "ping") {
        if ("from" in data) { // directed ping
          console.info("Received ping from " + data.from.toString() + " (" + data.from_client_addr.toString() + ")")
          this.ws.send(JSON.stringify({type: 'pong', dest: data.from, ref: data.ref}))
        } else this.ws.send(JSON.stringify({type: 'pong'}))
      } else {
        console.info(data);
        if ("ref" in data) {
          let cb = this.callbacks.get(data.ref)
          if (cb !== undefined) {
            this.callbacks.delete(data.ref)
            cb.callback(data, cb)
          }
        }
        if ("type" in data && this.typeHandlers.has(data.type)) {
          let typeHandler = this.typeHandlers.get(data.type);
          typeHandler && typeHandler.map((h) => h.callback(data, h))
        }
      }
    } catch (ex) {
      console.error(ex);
      console.info(JSON.parse(event.data))
    }
  }

  public promiseCmd(cmd:string, additional:any = {}){
    return new Promise((accept, reject) => {
      this.sendCmd(cmd, (response, _) => {
        if ("success" in response && response.success) accept(response)
        else reject(response)
      }, additional)
    })
  }

  public addTypeHandler(type: string, handler:ResponseCallback){
    let handlers = this.typeHandlers.get(type) || [];
    this.typeHandlers.set(type, [...handlers, {type: type, callback: handler}]);
  }

  constructor(server:string) {
    this.server = server
    this.initWebsocket();
  }

  private initWebsocket() {
    this.ws = new WebSocket(this.server)
    this.ws.onopen = () => {
      this.sendCmd('modeswitch', () => {
        typeof this.ready == 'function' && this.ready()
      }, {'mode': 'public'})
    };
    this.ws.onmessage = (event) => this.messageHandler(event)
    this.ws.onclose = () => {};
  }
}
