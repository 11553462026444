<template>
<div v-if="hasData">
<large-menubar :title="viewtitle"></large-menubar>
<div class="maincontent">
<group>
<cell :title="customerName" :inline-desc="customer.email" link="/profil"></cell>
</group>

<Callout v-if="order.status === ORDER_STATUS_PROCESSING"
header="Bereit für einen Termin?"
footer="Unkompliziert einen Walk-In Termin buchen">
<div id="messageGroup">
<div class="icon">
<a href="tel:+41445205285"><TelephoneIcon/></a>
</div>
<svg class="separator" viewBox="0 0 1 24.749">
<path fill="rgba(0,0,0,0)" stroke="rgba(112,112,112,1)" stroke-width="1px" stroke-linejoin="miter"
stroke-linecap="butt" stroke-miterlimit="4" shape-rendering="auto" id="Path_1"
d="M 0 0 L 0 24.748779296875">
</path>
</svg>
<div class="icon">
<a :href="'https://wa.me/41445205285?text=' +
encodeURIComponent('Bitte um Termin für Auftrag #' + order.order_id +'.\r\nMir passt es:\r\n' + proposedDates + '\r\n\r\nMfG '+ customerName)">
<font-awesome-icon :icon="['fab', 'whatsapp-square']"/></a>
</div>
<svg class="separator" viewBox="0 0 1 24.749">
<path fill="rgba(0,0,0,0)" stroke="rgba(112,112,112,1)" stroke-width="1px" stroke-linejoin="miter"
stroke-linecap="butt" stroke-miterlimit="4" shape-rendering="auto" id="Path_2"
d="M 0 0 L 0 24.748779296875">
</path>
</svg>
<div class="icon">
<a :href="'mailto:info@phoneuster.ch?subject=Terminanfrage%20Auftrag%20#' + order.order_id + '&body='+
encodeURIComponent('Guten Tag\r\n\r\nIch möchte gerne einen Termin vereinbaren für den Auftrag #' + order.order_id + '\r\nMir passt es am\r\n' + proposedDates + '\r\n\r\nMit freundlichen Grüssen\r\n' + customerName)
.replace(/!/g, '%21')
.replace(/'/g, '%27')
.replace(/\(/g, '%28')
.replace(/\)/g, '%29')
.replace(/\*/g, '%2A')"><MailIcon/></a>
</div>
</div>
<divider><small>oder</small></divider>
<small><router-link :to="$route.params.id+'/termine'">Online anfragen</router-link></small>
</Callout>
<Callout v-if="order.status === ORDER_STATUS_WAITING_FOR_DEVICE && closestAppointmentPreRepair">
<template v-slot:header>
<span v-if="closestAppointmentPreRepair.type === APPOINTMENT_TYPE_BRING_IN">
Ihre Reparatur findet statt am
</span>
<span v-else-if="closestAppointmentPreRepair.type === APPOINTMENT_TYPE_DROP_OFF">
Ihre Abgabetermin ist geplant am
</span>
<span v-else-if="closestAppointmentPreRepair.type === APPOINTMENT_TYPE_FETCH">
Wir kommen zur Abholung zu Ihnen am
</span>
<span v-else-if="closestAppointmentPreRepair.type === APPOINTMENT_TYPE_ON_SITE">
Wir kommen zur Reparatur zu Ihnen am
</span>
</template>
<span style="white-space: pre;">{{1000 * closestAppointmentPreRepair.start | twoLineDateTime}}</span>
<template v-slot:footer>
<div v-if="(closestAppointmentPreRepair.start - Date.now()/1000)/3600 < 4">
{{closestAppointmentPreRepair.start*1000 | fromNow}}
</div>
Geschätzte Dauer: {{closestAppointmentPreRepair.duration * 1000 | duration}}
<div v-if="closestAppointmentPreRepair.type === APPOINTMENT_TYPE_BRING_IN">
<div v-if="(closestAppointmentPreRepair.start - Date.now()/1000)/3600 < 12">
Wegzeit: {{routeInfo.duration.text}} ({{routeInfo.distance.text}} von {{shippingAddress | addressName}})
</div>
<div v-if="(closestAppointmentPreRepair.start - Date.now()/1000)/3600 < 12"><a
target="_blank"
:href="'https://www.google.com/maps/dir/?api=1&dir_action=navigate&destination='+
encodeURIComponent(((l) => l.street + ', ' + l.zip + ' ' + l.city)(storeLocations[0]))">
Navigiere &gt;
</a></div>
</div>
</template>
</Callout>
<Callout v-if="order.status === ORDER_STATUS_PENDING_REPAIR">
<template v-slot:header>
Wir arbeiten gerade an Ihrem Auftrag!
</template>
<span v-if="closestAppointmentDuringRepair">voraussichtlich etwa fertig {{1000 * (closestAppointmentDuringRepair.start+closestAppointmentDuringRepair.duration) | fromNow}}</span>
<span v-else>wird repariert</span>
<template v-slot:footer v-if="closestAppointmentDuringRepair">
Geschätzte Dauer: {{closestAppointmentDuringRepair.duration * 1000 | duration}}
<span
v-if="closestAppointmentDuringRepair && (Date.now() > (1000 * (closestAppointmentDuringRepair.start+closestAppointmentDuringRepair.duration)))">
<br/>
Ihre Reparatur scheint sich etwas zu verzögern. Wir bitten um Ihr Verständnis.
</span>
</template>
<template v-slot:footer
v-else-if="positions.reduce((sum, pos) => getServiceById(pos.service_fid).repair_duration_approx, 0)">
Geschätzte Dauer: {{positions.reduce((sum, pos) => getServiceById(pos.service_fid).repair_duration_approx, 0)
* 1000 | duration}}
<span
v-if="closestAppointmentDuringRepair && (Date.now() > (1000 * (closestAppointmentDuringRepair.start+closestAppointmentDuringRepair.duration)))">
<br/>
Ihre Reparatur scheint sich etwas zu verzögern. Wir bitten um Ihr Verständnis.
</span>
</template>
</Callout>
<Callout
v-if="(order.status === ORDER_STATUS_PENDING_RETURN_FETCH || order.status === ORDER_STATUS_PENDING_RETURN) && closestAppointmentAfterRepair">
<template v-slot:header>
<span v-if="closestAppointmentAfterRepair.type === APPOINTMENT_TYPE_RETRIEVE">
Ihr Auftrag ist bereit zur Abholung
</span>
<span v-else-if="closestAppointmentAfterRepair.type === APPOINTMENT_TYPE_DELIVER">
Wir bringen Ihren Auftrag zurück
</span>
</template>
<span style="white-space: pre;">{{1000 * closestAppointmentAfterRepair.start | twoLineDateTime}}</span>
<template v-slot:footer>
Geschätzte Dauer: {{closestAppointmentAfterRepair.duration * 1000 | duration}}
</template>
</Callout>
<Callout
v-if="(order.status === ORDER_STATUS_PENDING_RETURN_FETCH || order.status === ORDER_STATUS_PENDING_RETURN) && !closestAppointmentAfterRepair">
<template v-slot:header>
<span v-if="order.status === ORDER_STATUS_PENDING_RETURN_FETCH">
Ihr Auftrag ist bereit zur Abholung
</span>
<span v-else-if="order.status === ORDER_STATUS_PENDING_RETURN">
Wir bereiten gerade die Lieferung vor
</span>
</template>
<span style="white-space: pre;">Fertig!</span>
</Callout>
<Callout v-if="order.status === ORDER_STATUS_PENDING_RETURN_DLVR">
<template v-slot:header>
<span v-if="closestAppointmentSendBack">
Ihr Auftrag ist auf dem Weg zu Ihnen!
</span>
<span v-else>
Wir bereiten gerade den Versand vor:
</span>
</template>
<span
v-if="closestAppointmentSendBack && closestAppointmentSendBack.tracking_number"><small>{{ closestAppointmentSendBack.tracking_number }}</small></span>
<span
v-else-if="order.tracking_number"><small>{{ order.tracking_number }}</small>></span>
<span style="white-space: pre;" v-else-if="closestAppointmentSendBack">{{1000 * closestAppointmentSendBack.start | twoLineDateTime}}</span>
<span v-else>in Bearbeitung</span>
<template v-slot:footer>
Die Post stellt üblicherweise am nächsten Tag zu.
</template>
</Callout>
<Callout v-if="order.status === ORDER_STATUS_COMPLETED"
header="Auftrag abgeschlossen">
<span><CheckCircle style="height: 90px"/></span>
</Callout>
<Callout v-if="order.status === ORDER_STATUS_WAITING_FOR_PART"
header="Ihr Auftrag" footer="In der Regel dauert dies weniger als eine Woche.">
<span>wartet auf Ersatzteile</span>
</Callout>

<group title="Services" class="icons">
<cell v-for="position in positions" :key="position.order_position_id"
:title="getPositionTitleByPosition(position)" :inline-desc="getPositionSubtitleByPosition(position)"
:value="position.value | currency">
<!-- TODO add link to service detail view -->
<template v-slot:icon>
<div
:style="'background-image: url(\''+getPositionImage(position)+'\')'"
class="img-placeholder"></div>
</template>
</cell>
</group>

<group title="Nächste Schritte">
<cell title="Termine & Nachrichten"
:value="appointments.length === 0 ? 'Nicht vereinbart' : appointments.length === 1 ? '1 Termin' : (appointments.length + ' Termine')"
:link="$route.params.id+'/termine'"></cell>
<cell title="Bezahlung" value="nicht Verfügbar"></cell>
<cell title="Kontakt" :link="$route.params.id+'/kontakt'"></cell>
</group>
</div>
</div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import Cell from 'vux/src/components/cell/index.vue'
import Group from 'vux/src/components/group/index.vue'
import Divider from 'vux/src/components/divider/index.vue'

import LargeMenubar from '../components/LargeMenubar'
import Callout from '../components/Callout'
import {DateTime, Duration} from 'luxon'
import {serviceImageLinks} from '../store'
import TelephoneIcon from '../../static/icon/SVG/call.svg'
import MailIcon from '../../static/icon/SVG/email.svg'
import CheckCircle from '../../static/icon/SVG/check.svg'

/* global google */

let longDateTimeFormat
try {
longDateTimeFormat = new Intl.DateTimeFormat('de-ch', {
dateStyle: 'medium',
timeStyle: 'short',
month: '2-digit'
})
} catch (e) {
longDateTimeFormat = new Intl.DateTimeFormat('de-ch', {
weekday: 'long',
day: 'numeric',
month: 'short',
year: 'numeric',
hour: '2-digit',
minute: '2-digit',
})
}

export default {
components: {
Callout,
LargeMenubar,
Group,
Cell,
TelephoneIcon,
MailIcon,
CheckCircle,
Divider
},
data () {
return {





___prevOrderStatus: 0,
routeInfo: {
noData: true,
distance: {
text: 'ausgehend'
},
duration: {
text: 'berechne...'
}
}
}
},
watch: {
order: {
immediate: true,
deep: true,
handler: function (newOrder, oldOrder) {
if (((!oldOrder || oldOrder.order_id !== newOrder.order_id) || this.___prevOrderStatus !== newOrder.status) &&
newOrder.status === (this.ORDER_STATUS_WAITING_FOR_DEVICE || 3) &&
this.closestAppointmentPreRepair &&
this.closestAppointmentPreRepair.type === (this.APPOINTMENT_TYPE_BRING_IN || 'bring-in')) {
this.___prevOrderStatus = newOrder.status
let routing = () => this.directionsService.route(
{
origin: {query: this.shippingAddress.street + ', ' + this.shippingAddress.zip + ' ' + this.shippingAddress.city},
destination: {query: ((l) => l.street + ', ' + l.zip + ' ' + l.city)(this.storeLocations[0])},
travelMode: google.maps.DirectionsTravelMode.DRIVING
},
(response, status) => {
if (status === 'OK') {
this.routeInfo = response.routes[0].legs[0]
} else {
console.error('Directions request failed due to ' + status)
}
})
let late = window.initMaps
if (this.directionsService === undefined) {
window.initMaps = () => {
late()
routing()
}
}
}
}
}
},
computed: {
viewtitle: (t) => 'Auftrag #' + t.order.order_id,
...mapState({
hasData: 'hasData',
order: 'order',
customer: 'customer',
appointments: 'appointments',
storeLocations: 'storeLocations',
positions: (state) => state.positions.sort((a, b) => a.sort < b.sort ? -1 : 1)

}),
...mapGetters([
'customerName',
'getServiceById',
'getAddressById',
'getDeviceImageThumbnailById',
'getPositionTitleByPosition',
'getPositionSubtitleByPosition',
'getClosestAppointmentCond'

]),
shippingAddress: (t) => t.getAddressById(t.order.shipping_address_fid),
closestAppointmentPreRepair: (t) => t.getClosestAppointmentCond(apt => apt.type === t.APPOINTMENT_TYPE_BRING_IN || apt.type === t.APPOINTMENT_TYPE_ON_SITE || apt.type === t.APPOINTMENT_TYPE_DROP_OFF || apt.type === t.APPOINTMENT_TYPE_FETCH),
closestAppointmentDuringRepair: (t) => t.getClosestAppointmentCond(apt => apt.type === t.APPOINTMENT_TYPE_BRING_IN || apt.type === t.APPOINTMENT_TYPE_PLANNED_REPAIR || apt.type === t.APPOINTMENT_TYPE_ON_SITE),
closestAppointmentAfterRepair: (t) => t.getClosestAppointmentCond(apt => apt.type === t.APPOINTMENT_TYPE_RETRIEVE || apt.type === t.APPOINTMENT_TYPE_DELIVER),
closestAppointmentSendBack: (t) => t.getClosestAppointmentCond(apt => apt.type === t.APPOINTMENT_TYPE_SEND_BACK),
proposedDates: () => '',
getPositionImage: (t) => (position) => {
if (position.service_fid in serviceImageLinks) return serviceImageLinks[position.service_fid][1]
let realDeviceFid = t.getServiceById(position.service_fid).real_device_fid
return t.getDeviceImageThumbnailById(realDeviceFid) || serviceImageLinks[0][1]
}
},
filters: {
currency: (val) => val === null ? 'N/A' : val === 0 ? 'kostenlos' : ('CHF ' + (Math.round(val * 100) % 100 === 0 ? (Math.round(val * 100) / 100) : (Math.round(val * 100) / 100).toFixed(2))),

twoLineDateTime: (val) => val === 0 ? '' : longDateTimeFormat.formatToParts(new Date(val)).map(({type, value}) => {
switch (value) {
case ', ':
return '\n'
default :
return value
}
}).reduce((string, part) => string + part),
duration: (millis) => Duration.fromMillis(millis).toFormat('hh:mm'),
fromNow: (millitime) => DateTime.fromMillis(millitime).setLocale('de-ch').toRelative(),
addressName: (address) => {
let typemap = {
'default': 'Standard',
'business': 'Geschäft'
}
return address.address_name || typemap[address.type] || 'Adresse'
}
},
mounted () {
},
beforeCreate () {
this.ORDER_STATUS_PROCESSING = 0
this.ORDER_STATUS_PENDING_PRE_PAYMENT = 1
this.ORDER_STATUS_WAITING_FOR_PART = 2
this.ORDER_STATUS_WAITING_FOR_DEVICE = 3
this.ORDER_STATUS_PENDING_REPAIR = 4
this.ORDER_STATUS_PENDING_PRE_RETURN_PAYMENT = 5
this.ORDER_STATUS_PENDING_RETURN_DLVR = 6
this.ORDER_STATUS_PENDING_RETURN = 7
this.ORDER_STATUS_PENDING_RETURN_FETCH = 8
this.ORDER_STATUS_PENDING_POST_PAYMENT = 9
this.ORDER_STATUS_COMPLETED = 10

this.APPOINTMENT_TYPE_ON_SITE = 'on-site'
this.APPOINTMENT_TYPE_BRING_IN = 'bring-in'
this.APPOINTMENT_TYPE_PLANNED_REPAIR = 'planned-repair'
this.APPOINTMENT_TYPE_DROP_OFF = 'drop-off'
this.APPOINTMENT_TYPE_RETRIEVE = 'retrieve'
this.APPOINTMENT_TYPE_FETCH = 'fetch'
this.APPOINTMENT_TYPE_DELIVER = 'deliver'
this.APPOINTMENT_TYPE_SEND_IN = 'send-in'
this.APPOINTMENT_TYPE_SEND_BACK = 'send-back'

window.initMaps = () => {
this.directionsService = new google.maps.DirectionsService()
}
if (document.querySelector('#gmapsScript') === null) {
let externalScript = document.createElement('script')
externalScript.setAttribute('id', 'gmapsScript')
externalScript.setAttribute('defer', '')
externalScript.setAttribute('async', '')
externalScript.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?callback=initMaps&language=de-ch&region=ch&key=' + this.$store.state.mapsApiKey)
document.head.appendChild(externalScript)
} else {
window.initMaps()
}
}
}
</script>

<style lang="scss">

.mainview {
height: 100vh;
}
.maincontent{
height: 100%;
overflow-y: scroll;
}

#messageGroup {
width: 80vw;
max-width: 300px;
height: 54px;
margin: 0 auto;
display: flex;
justify-content: space-between;

.separator {
overflow: visible;
width: 1px;
height: 24.749px;
margin: 15.126px 0;
transform: matrix(1, 0, 0, 1, 0, 0);
}

.icon {
white-space: nowrap;
text-align: center;
font-family: -apple-system, "SF Pro Text",
BlinkMacSystemFont,
"Segoe UI",
Roboto,
Helvetica,
Arial,
sans-serif,
"Apple Color Emoji",  /* Emojis*/
"Segoe UI Emoji", /* Emojis*/
"Segoe UI Symbol"; /* Emojis*/;
font-style: normal;
font-weight: normal;
font-size: 45px;
color: rgba(112, 112, 112, 1);
svg {
height: 54px;
&.fa-whatsapp-square {
width: unset;
height: 62px;
margin-top: -4px;
fill: #707070;
& > path {
fill: #707070;
}
}
}
}
}
</style>
