<template>
<div id="app">
<transition :name="transitionName" v-if="!error">
<router-view class="mainview"></router-view>
</transition>
<msg v-else
:title="error"
:description="errorDetail"
icon="warn">
</msg>
</div>
</template>

<script>
import Vue from 'vue'
import LoadingPlugin from 'vux/src/plugins/loading/index.js'
import Msg from 'vux/src/components/msg/index.vue'

Vue.use(LoadingPlugin)

export default {
components: {
Msg
},
name: 'app',
data () {
return {
transitionName: 'slide-left',
hasError: false,
error: '',
errorDetail: ''
}
},
beforeRouteUpdate (to, from, next) {
const toDepth = to.path.split('/').length
const fromDepth = from.path.split('/').length
this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
next()
},
methods: {
errorHandler (reason) {
this.hasError = true
if (reason.error === 'Invalid token') {
this.error = 'Zugriff verweigert'
this.errorDetail = 'Benutzen Sie die Zurück-Taste oder öffnen Sie den erhaltenen Link erneut.'
} else if (reason.error === 'Invalid state') {
this.error = 'Etwas ging schief'
this.errorDetail = 'Bitte versuchen Sie es erneut.'
} else {
this.error = 'Unbekannter Fehler'
this.errorDetail = reason.error
}
this.$vux.loading.hide()
}
},
mounted () {
if ('id' in this.$route.params) {
this.$vux.loading.show({
text: 'Verbinde...'
})
this.$store.state.com.ready = () => this.$store.dispatch('setIdentity', this.$route.params.id).then(
() => this.$store.dispatch('refresh', [['order', 'customer', 'positions', 'services', 'devices', 'appointments', 'addresses'], 0]).then(
() => this.$vux.loading.hide(),
this.errorHandler
),
this.errorHandler
)
}
}
}
</script>

<style lang="less">
@import '~vux/src/styles/reset.less';

body {
background: #EFEFF4;
}

.mainview{
position: absolute;
width: 100%;
transition: all .5s cubic-bezier(.55,0,.1,1);
}

.slide-left-enter, .slide-right-leave-active {
opacity: 0;
transform: translate(60px, 0);
}
.slide-left-leave-active, .slide-right-enter {
opacity: 0;
transform: translate(-60px, 0);
}


.icons > .weui-cells {
.weui-cell {
display: grid;
grid-template-columns: 45px 11fr 1fr;
grid-template-rows: 36px;
&:before{
left: 60px;
}
.vux-cell-bd {
overflow: hidden;
p{
max-height: 22px;
label {
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
width: 100%;
}
}
.vux-label-desc {
overflow: hidden;
text-overflow: ellipsis;
}
}
.weui-cell__ft {
white-space: nowrap;
}
}
}

.weui-cells {
.weui-cell {

max-height: 36px;
&.oversize {
max-height: unset;
}

.weui-cell__hd {
img, .img-placeholder {
width: 28px;
height: 28px;
margin: -3px 14px -3px 1px;
background-repeat: no-repeat;
background-position: center;
background-size: contain;
border: 0;
}
}

.vux-cell-bd {
position: relative;
display: inline-block;

font-family: -apple-system, "SF Pro Text",
BlinkMacSystemFont,
"Segoe UI",
Roboto,
Helvetica,
Arial,
sans-serif,
"Apple Color Emoji",  /* Emojis*/
"Segoe UI Emoji", /* Emojis*/
"Segoe UI Symbol"; /* Emojis*/;
white-space: nowrap;
text-align: left;
font-style: normal;
font-weight: normal;

.vux-label-desc {
line-height: 20px;
font-size: 15px;
color: rgba(60, 60, 67, 0.6);
letter-spacing: -0.16px;
display: block;
}

p {
line-height: 22px;
font-size: 17px;
color: rgba(0, 0, 0, 1);
letter-spacing: -0.24px;
}
}

&.weui-cell_access {
.weui-cell__ft {
padding-right: 18px !important;
}
}
}
}
</style>

<style lang="css">

/*---------------------------------------------------------------------------*
* SF UI Display
*---------------------------------------------------------------------------*/

/** Black */
@font-face {
font-family: "SF Display";
font-weight: 900;
src: url("https://sf.abarba.me/SF-UI-Display-Black.otf");
}

/** Bold */
@font-face {
font-family: "SF Display";
font-weight: 700;
src: url("https://sf.abarba.me/SF-UI-Display-Bold.otf");
}

/** Heavy */
@font-face {
font-family: "SF Display";
font-weight: 800;
src: url("https://sf.abarba.me/SF-UI-Display-Heavy.otf");
}

/** Light */
@font-face {
font-family: "SF Display";
font-weight: 200;
src: url("https://sf.abarba.me/SF-UI-Display-Light.otf");
}

/** Medium */
@font-face {
font-family: "SF Display";
font-weight: 500;
src: url("https://sf.abarba.me/SF-UI-Display-Medium.otf");
}

/** Regular */
@font-face {
font-family: "SF Display";
font-weight: 400;
src: url("https://sf.abarba.me/SF-UI-Display-Regular.otf");
}

/** Semibold */
@font-face {
font-family: "SF Display";
font-weight: 600;
src: url("https://sf.abarba.me/SF-UI-Display-Semibold.otf");
}

/** Thin */
@font-face {
font-family: "SF Display";
font-weight: 300;
src: url("https://sf.abarba.me/SF-UI-Display-Thin.otf");
}

/** Ultralight */
@font-face {
font-family: "SF Display";
font-weight: 100;
src: url("https://sf.abarba.me/SF-UI-Display-Ultralight.otf");
}

/*---------------------------------------------------------------------------*
* SF UI Text
*---------------------------------------------------------------------------*/

/** Bold */
@font-face {
font-family: "SF Text";
font-weight: 700;
src: url("https://sf.abarba.me/SF-UI-Text-Bold.otf");
}

/** Bold Italic */
@font-face {
font-family: "SF Text";
font-weight: 700;
font-style: italic;
src: url("https://sf.abarba.me/SF-UI-Text-BoldItalic.otf");
}

/** Heavy */
@font-face {
font-family: "SF Text";
font-weight: 800;
src: url("https://sf.abarba.me/SF-UI-Text-Heavy.otf");
}

/** Heavy Italic */
@font-face {
font-family: "SF Text";
font-weight: 800;
font-style: italic;
src: url("https://sf.abarba.me/SF-UI-Text-HeavyItalic.otf");
}

/** Light */
@font-face {
font-family: "SF Text";
font-weight: 200;
src: url("https://sf.abarba.me/SF-UI-Text-Light.otf");
}

/** Light Italic */
@font-face {
font-family: "SF Text";
font-weight: 200;
font-style: italic;
src: url("https://sf.abarba.me/SF-UI-Text-LightItalic.otf");
}

/** Medium */
@font-face {
font-family: "SF Text";
font-weight: 500;
src: url("https://sf.abarba.me/SF-UI-Text-Medium.otf");
}

/** Medium Italic */
@font-face {
font-family: "SF Text";
font-weight: 500;
font-style: italic;
src: url("https://sf.abarba.me/SF-UI-Text-MediumItalic.otf");
}

/** Regular */
@font-face {
font-family: "SF Text";
font-weight: 400;
src: url("https://sf.abarba.me/SF-UI-Text-Regular.otf");
}

/** Regular Italic */
@font-face {
font-family: "SF Text";
font-weight: 400;
font-style: italic;
src: url("https://sf.abarba.me/SF-UI-Text-RegularItalic.otf");
}

/** Semibold */
@font-face {
font-family: "SF Text";
font-weight: 600;
src: url("https://sf.abarba.me/SF-UI-Text-Semibold.otf");
}

/** Semibold Italic */
@font-face {
font-family: "SF Text";
font-weight: 600;
font-style: italic;
src: url("https://sf.abarba.me/SF-UI-Text-SemiboldItalic.otf");
}

/** Ultrathin */
@font-face {
font-family: "SF Text";
font-weight: 100;
src: url("https://sf.abarba.me/SF-UI-Text-Ultrathin.otf");
}

/** Ultrathin Italic */
@font-face {
font-family: "SF Text";
font-weight: 100;
font-style: italic;
src: url("https://sf.abarba.me/SF-UI-Text-UltrathinItalic.otf");
}

/*---------------------------------------------------------------------------*
* SF Mono
*---------------------------------------------------------------------------*/

/** Bold */
@font-face {
font-family: "SF Mono";
font-weight: 700;
src: url("https://sf.abarba.me/SFMono-Bold.otf");
}

/** Bold Italic */
@font-face {
font-family: "SF Mono";
font-weight: 700;
font-style: italic;
src: url("https://sf.abarba.me/SFMono-BoldItalic.otf");
}

/** Heavy */
@font-face {
font-family: "SF Mono";
font-weight: 800;
src: url("https://sf.abarba.me/SFMono-Heavy.otf");
}

/** Heavy Italic */
@font-face {
font-family: "SF Mono";
font-weight: 800;
font-style: italic;
src: url("https://sf.abarba.me/SFMono-HeavyItalic.otf");
}

/** Light */
@font-face {
font-family: "SF Mono";
font-weight: 200;
src: url("https://sf.abarba.me/SFMono-Light.otf");
}

/** Light Italic */
@font-face {
font-family: "SF Mono";
font-weight: 200;
font-style: italic;
src: url("https://sf.abarba.me/SFMono-LightItalic.otf");
}

/** Medium */
@font-face {
font-family: "SF Mono";
font-weight: 500;
src: url("https://sf.abarba.me/SFMono-Medium.otf");
}

/** Medium Italic */
@font-face {
font-family: "SF Mono";
font-weight: 500;
font-style: italic;
src: url("https://sf.abarba.me/SFMono-MediumItalic.otf");
}

/** Regular */
@font-face {
font-family: "SF Mono";
font-weight: 400;
src: url("https://sf.abarba.me/SFMono-Regular.otf");
}

/** Regular Italic */
@font-face {
font-family: "SF Mono";
font-weight: 400;
font-style: italic;
src: url("https://sf.abarba.me/SFMono-RegularItalic.otf");
}

/** Semibold */
@font-face {
font-family: "SF Mono";
font-weight: 600;
src: url("https://sf.abarba.me/SFMono-Semibold.otf");
}

/** Semibold Italic */
@font-face {
font-family: "SF Mono";
font-weight: 600;
font-style: italic;
src: url("https://sf.abarba.me/SFMono-SemiboldItalic.otf");
}
</style>
