<template>
  <div :class="[bg, barHeight, 'navBarLarge']">
    <div class="left accessory" v-if="parentTitle" @click="leftAccessoryClick" ref="leftAccessory">
      <div class="parent-title" :style="{opacity: canShowParentTitle ? 1 : 0}">
        {{parentTitle}}
      </div>
      <div class="chevron">
        <ChevronLeft />
      </div>
    </div>
    <h1>
      {{title}}
    </h1>
    <div class="right accessory">
      <slot name="right-accessories">
        <!--<div class="button">
          <div class="symbol">
            <span>􀅼</span>
          </div>
        </div>-->
      </slot>
    </div>
  </div>
</template>

<script lang="js">
  import ChevronLeft from '../../static/icon/SVG/chevron-left.svg'

  export default {
    components: {
      ChevronLeft
    },
    props: {
      title: String,
      parentTitle: String,
      bg: {
        type: String,
        default: 'grey',
        required: false,
        validator: (value) => ['grey', 'white'].includes(value)
      },
      barHeight: {
        type: String,
        default: 'large',
        required: false,
        validator: (value) => ['large', 'small'].includes(value)
      },
      parentUrl: {
        type: [String, Number],
        default: -1,
        required: false
      }
    },
    data: () => {
      return {
        canShowParentTitle: true
      }
    },
    methods: {
      leftAccessoryClick () {
        if (typeof this.parentUrl === 'number') {
          this.$router.go(this.parentUrl)
        } else {
          if (this.parentUrl.startsWith('https://')) {
            window.location = this.parentUrl
          } else {
            this.$router.replace(this.parentUrl)
          }
        }
      },
      updateParentTitleVisibility () {
        this.$nextTick(() => {
          this.canShowParentTitle = this.$refs.leftAccessory.querySelector('.parent-title').clientWidth >= this.$refs.leftAccessory.querySelector('.parent-title').scrollWidth
        })
      }
    },
    mounted () {
      if (this.parentTitle) {
        this.updateParentTitleVisibility()
        window.addEventListener('resize', () => this.updateParentTitleVisibility())
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .navBarLarge {
    position: relative;
    margin: 0;

    display: grid;
    grid-template-columns: 1fr auto;

    &.large {
      height: 140px;
      grid-template-rows: 44px 44px 52px;
      h1 {
        grid-row: 3;
        grid-column: 1 / 3;
      }
    }

    &.small {
      height: 93px;
      grid-template-rows: 44px 44px;
      grid-template-columns: minmax(27px, 1fr) auto 1fr;

      h1 {
        text-align: center;
        font-family: -apple-system, "SF Pro Text",
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Helvetica,
        Arial,
        sans-serif,
        "Apple Color Emoji",  /* Emojis*/
        "Segoe UI Emoji", /* Emojis*/
        "Segoe UI Symbol"; /* Emojis*/;
        font-size: 17px;
        letter-spacing: -0.24px;
        grid-row: 2;
        grid-column: 2;
      }
    }

    &.white {
      background: #ffffff;
    }


    h1 {
      position: relative;
      margin: 3px 14px 0;
      white-space: nowrap;
      line-height: 41px;
      text-align: left;
      font-family: -apple-system, "SF Pro Display",
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      Helvetica,
      Arial,
      sans-serif,
      "Apple Color Emoji",  /* Emojis*/
      "Segoe UI Emoji", /* Emojis*/
      "Segoe UI Symbol"; /* Emojis*/;
      font-style: normal;
      font-weight: bold;
      font-size: 34px;
      color: #000000;
      letter-spacing: 0.04px;

      overflow: hidden;

    }

    .accessory {
      position: relative;
      height: 44px;
      font-style: normal;
      font-weight: normal;
      font-family: -apple-system, "SF Pro Text",
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      Helvetica,
      Arial,
      sans-serif,
      "Apple Color Emoji",  /* Emojis*/
      "Segoe UI Emoji", /* Emojis*/
      "Segoe UI Symbol"; /* Emojis*/;
      text-align: left;
      white-space: nowrap;
      color: rgba(0, 122, 255, 1);

      &.left {
        grid-row: 2;
        grid-column: 1;

        cursor: pointer;

        .parent-title {
          position: relative;
          margin-left: 28px;
          margin-top: 11.2px;
          overflow: hidden;
          line-height: 22px;
          font-size: 17px;
          letter-spacing: -0.32px;
        }

        .chevron {
          position: absolute;
          left: 10px;
          top: 6px;
          overflow: visible;
          width: 19px;
          font-size: 24px;
          svg {
            height: 23px;
          }
        }
      }

      &.right {
        margin-right: 10px;
        grid-row: 2;
        grid-column: 2;

        display: grid;
        grid-template-columns: repeat(auto-fit, 44px);
        grid-template-rows: 44px;

        .button {
          position: relative;
          width: 44px;
          height: 44px;
          grid-row: 1;

          .symbol {
            position: relative;
            width: 100%;
            text-align: center;
            font-weight: lighter;
            font-size: 22px;
            line-height: 44px;
            letter-spacing: -0.0040999999642372135px;
          }
        }
      }
    }
  }
</style>
