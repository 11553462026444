import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const deviceImageLinks = {
  47: ['/static/device/iphone-11-device.png', '/static/device/iphone-11-device@0.25x.png'],
  48: ['/static/device/iphone-11-pro-device.png', '/static/device/iphone-11-pro-device@0.25x.png'],
  49: ['/static/device/iphone-11-pro-max-device.png', '/static/device/iphone-11-pro-max-device@0.25x.png'],
  18: ['/static/device/iphone-xs-max-device.png', '/static/device/iphone-xs-max-device@0.25x.png'],
  17: ['/static/device/iphone-xs-device.png', '/static/device/iphone-xs-device@0.25x.png'],
  16: ['/static/device/iphone-xr-device.png', '/static/device/iphone-xr-device@0.25x.png'],
  15: ['/static/device/iphone-x-device.png', '/static/device/iphone-x-device@0.25x.png'],
  13: ['/static/device/iphone-8-device.png', '/static/device/iphone-8-device@0.25x.png'],
  14: ['/static/device/iphone-8-plus-device.png', '/static/device/iphone-8-plus-device@0.25x.png'],
  11: ['/static/device/iphone-7-device.png', '/static/device/iphone-7-device@0.25x.png'],
  12: ['/static/device/iphone-7-plus-device.png', '/static/device/iphone-7-plus-device@0.25x.png'],
  8: ['/static/device/iphone-6s-device-2.png', '/static/device/iphone-6s-device-2@0.25x.png'],
  9: ['/static/device/iphone-6s-plus-device-2.png', '/static/device/iphone-6s-plus-device-2@0.25x.png'],
  6: ['/static/device/iphone-6-device-1.png', '/static/device/iphone-6-device-1@0.25x.png'],
  7: ['/static/device/iphone-6-plus-device-2.png', '/static/device/iphone-6-plus-device-2@0.25x.png'],
  10: ['/static/device/iphone-se-device-1.png', '/static/device/iphone-se-device-1@0.25x.png'],
  4: ['/static/device/iphone-se-device-1.png', '/static/device/iphone-se-device-1@0.25x.png'],
  5: ['/static/device/iphone-5c-device-1.png', '/static/device/iphone-5c-device-1-181x150.png'],
  3: ['/static/device/iphone-5-device-1.png', '/static/device/iphone-5-device-1-181x150.png'],
  2: ['/static/device/iphone-44s-device.png', '/static/device/iphone-44s-device-181x150.png'],
  1: ['/static/device/iphone-44s-device.png', '/static/device/iphone-44s-device-181x150.png']
}

export const serviceImageLinks = {
  65: ['/static/service/bringin.png', '/static/service/bringin@0.5x.png'],
  31: ['/static/service/mailin.png', '/static/service/mailin@0.5x.png'],
  66: ['/static/service/mailin.png', '/static/service/mailin@0.5x.png'],
  33: ['/static/service/collect_eve.png', '/static/service/collect_eve@0.5x.png'],
  32: ['/static/service/meet.png', '/static/service/meet@0.5x.png'],
  51: ['/static/service/gift.png', '/static/service/gift@0.5x.png'],
  155: ['/static/service/gift.png', '/static/service/gift@0.5x.png'],
  156: ['/static/service/gift.png', '/static/service/gift@0.5x.png'],
  8: ['/static/service/discount.png', '/static/service/discount@0.5x.png'],
  14: ['/static/service/bagplus.png', '/static/service/bagplus@0.5x.png'],
  129: ['/static/service/bag.png', '/static/service/bag@0.5x.png'],
  22: ['/static/service/sqrt.png', '/static/service/sqrt@0.5x.png'],
  10: ['/static/service/wrench.png', '/static/service/wrench@0.5x.png'],
  3: ['/static/service/hammer.png', '/static/service/hammer@0.5x.png'],
  29: ['/static/service/hammer.png', '/static/service/hammer@0.5x.png'],
  9: ['/static/service/bandage.png', '/static/service/bandage@0.5x.png'],
  0: ['/static/service/scribble.png', '/static/service/scribble@0.5x.png']
}

const cal_parttypes = {
    "CHAIR": 'Vorsitzender',
    "REQ-PARTICIPANT": 'Anwesenheit erforderlich',
    "OPT-PARTICIPANT": 'Anwesenheit optional',
    "NON-PARTICIPANT": 'nur zur Information',
}

const cal_partstat = {
    "NEEDS-ACTION": 'unbestätigt',
    "DECLINED": 'abgelehnt',
    "ACCEPTED": 'bestätigt',
    "TENTATIVE": 'mit Vorbehalt',
    "DELEGATED": 'delegiert',
}

export default new Vuex.Store({
  state: {
    accessKey: 0,
    customer: {
      name: '',
      email: ''
    },
    addresses: [],
    order: false,
    positions: [],
    devices: [],
    services: false,
    appointments: [],
    com: null,
    hasData: false,
    storeLocations: [
      {
        name: 'PhoneUster',
        street: 'Brunnenstrasse 1',
        city: 'Uster',
        zip: 8610
      },
      {
        name: 'PhoneUster Verwaltung',
        street: 'Burstwiesenstrasse 53',
        city: 'Greifensee',
        zip: 8606
      }
    ],
    mapsApiKey: 'AIzaSyAtXDOqhHsvSxZZcaEX2ZkdkA3zYU8F91I',
    token: '',
    timestamps: {},
    availability: [],
  },
  getters: {
    customerName: state => state.customer.vorname + ' ' + state.customer.nachname,
    getClosestAppointmentCond: state => cond => state.appointments.filter(cond).reduce((min, apt) => Math.abs(1000 * apt.start - Date.now()) < Math.abs(1000 * min.start - Date.now()) ? apt : min, state.appointments.find(cond)),
    getAppointmentsByStart: state => state.appointments.sort((a, b) => b.start - a.start),
    getClosestAppointment: state => state.appointments.reduce((min, apt) => Math.abs(1000 * apt.start - Date.now()) < Math.abs(1000 * min.start - Date.now()) ? apt : min, state.appointments[0]),
    getNextAppointmentCond: (state, getters) => cond => state.appointments.filter(cond).reduce((min, apt) => (1000 * apt.start > Date.now() && 1000 * apt.start < min.start) ? apt : min, getters.getClosestAppointment),
    getNextAppointment: (state, getters) => state.appointments.reduce((min, apt) => (1000 * apt.start > Date.now() && 1000 * apt.start < min.start) ? apt : min, getters.getClosestAppointment),
    getServiceById: state => (id) => state.services.find(service => service.service_id === id),
    getDeviceById: state => (id) => state.devices.find(device => device.real_device_id === id),
    getAddressById: state => (id) => state.addresses.find(address => address.customer_address_id === id),
    getDeviceImagesById: () => id => (id in deviceImageLinks) ? deviceImageLinks[id] : undefined,
    getParttype: () => parttype => (parttype in cal_parttypes) ? cal_parttypes[parttype] : undefined,
    getPartstat: () => partstat => (partstat in cal_partstat) ? cal_partstat[partstat] : undefined,
    getRepairDuration: (state, getters) => state.positions.reduce((p, pos) => p+getters.getServiceById(pos.service_fid).repair_duration_approx,0),
    getDeviceImageThumbnailById: (state, getters) => id => {
      let imgs = getters.getDeviceImagesById(id)
      return typeof imgs === 'undefined' ? undefined : imgs[1]
    },
    getDeviceImageFullById: (state, getters) => id => {
      let imgs = getters.getDeviceImagesById(id)
      return typeof imgs === 'undefined' ? undefined : imgs[0]
    },
    getPositionTitleByPosition: (state, getters) => position => {
      let svc = getters.getServiceById(position.service_fid)
      if (svc.categorizing_service_title !== null) return svc.categorizing_service_title
      if (svc.real_device_fid !== null && getters.getDeviceById(svc.real_device_fid) !== null) return getters.getDeviceById(svc.real_device_fid).name
      return svc.name
    },
    getPositionSubtitleByPosition: (state, getters) => position => {
      let svc = getters.getServiceById(position.service_fid)
      if (svc.narrowing_service_subtitle !== null) return svc.narrowing_service_subtitle
      if (svc.real_device_fid !== null && getters.getDeviceById(svc.real_device_fid) !== null) return svc.name
      return ''
    }
  },
  mutations: {
    setToken (state, token) {
      state.token = token
      if (localStorage) localStorage.token = token
    },
    setCustomer (state, customer) {
      state.customer = customer
      state.hasData = true
    },
    setAddresses (state, addresses) {
      state.addresses = addresses
      state.hasData = true
    },
    setOrder (state, order) {
      state.order = order
      state.hasData = true
    },
    setPositions (state, positions) {
      state.positions = positions
      state.hasData = true
    },
    setServices (state, services) {
      state.services = services
      state.hasData = true
    },
    setAppointments (state, appointments) {
      state.appointments = appointments
      state.hasData = true
    },
    setAccessKeyInternal (state, accessKey) {
      state.hasData = state.accessKey === accessKey
      state.timestamps = state.accessKey === accessKey ? state.timestamps : {}
      state.accessKey = accessKey
    },
    setDevices (state, newDevices) {
      state.devices = [...newDevices, ...state.devices.filter(device => newDevices.findIndex(nd => nd.real_device_id === device.real_device_id) === -1)]
    },
    updateDataset (state, [set, newData, keyAttr, keyVal]) {
      if (set in state) {
        let datasetCopy = state[set]
        if (keyAttr in datasetCopy && datasetCopy[keyAttr] === keyVal) {
          state[set] = {...datasetCopy, ...newData}
        } else if (datasetCopy instanceof Array) {
          state[set] = datasetCopy.map((obj) => (keyAttr in obj && obj[keyAttr] === keyVal) ? {...obj, ...newData} : obj)
        }
      }
    },
    addAvailability (state, availability) {
      state.availability = [availability, ...state.availability.filter(existing => (existing.until <= availability.from || existing.from >= availability.until))]
    },
  },
  actions: {
    /**
     * @param commit () -> ()
     * @param state S
     * @param what string[]
     * @param cacheDuration number
     * @returns {Promise<any>}|void
     */
    refresh ({commit, state}, [what, cacheDuration]) {
      let exp = Date.now() - cacheDuration
      let filteredData = what.filter((key) => !(key in state.timestamps) || state.timestamps[key] < exp)
      if (filteredData.length === 0) return
      return state.com.promiseCmd('getInfo', {
        'data': filteredData
      }).then(
        // handle success
        (data) => {
          for (let dataKey in data.data) {
            commit('set' + dataKey.charAt(0).toUpperCase() + dataKey.slice(1), data.data[dataKey])
            state.timestamps[dataKey] = Date.now()
          }
        }
      )
    },
    getDevices ({commit, state}, devices) {
      if (typeof devices !== 'object') devices = [devices]
      return state.com.promiseCmd('getRealDevices', {'data': devices}).then(
        // handle success
        (resp) => {
          commit('setDevices', resp.data.devices)
        }
      )
    },
    setIdentity ({commit, state}, identity) {
      return state.com.promiseCmd('setIdentity', {'id': identity, 'token': state.token}).then(
        // handle success
        () => commit('setAccessKeyInternal', identity)
      )
    },
    updatePartstat ({commit, state}, [appointmentId, newPartstat]) {
      return state.com.promiseCmd('setPartstat', {'id': appointmentId, 'partstat': newPartstat}).then(
        // handle success
        (resp) => {
          return commit('setAppointments', resp.data.appointments)
        }
      )
    },
    updateSuggestedTime ({commit, state}, [appointmentId, stime]) {
      return state.com.promiseCmd('setSuggestedTime', {'id': appointmentId, 'suggested_time': stime}).then(
          // handle success
          (resp) => {
            return commit('setAppointments', resp.data.appointments)
          }
      )
    },
    getAvailability ({commit}) {
      return fetch(process.env.VUE_APP_PRA_API_PATH + 'appointment/availability')
          .then(response => response.json())
          .then(
          // handle success
          (resp) => {
            return commit('addAvailability', resp)
          }
      )
    },
    getMoreAvailability ({commit, state}, [increment]) {
      let curdate = state.availability.reduce((p, c) => Math.max(p, c.until), 0) || Math.floor(new Date()/1000);
      return fetch(process.env.VUE_APP_PRA_API_PATH + 'appointment/availability', {
        method: "post",
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({from: curdate, until: curdate+increment})
      })
          .then(response => response.json())
          .then(
          // handle success
          (resp) => {
            return commit('addAvailability', resp)
          }
      )
    },
    addAppointmentRequest ({commit, state}, [start, duration]) {
      return state.com.promiseCmd('addAppointmentRequest', {'start': start, 'duration': duration}).then(
          // handle success
          (resp) => {
            return commit('setAppointments', resp.data.appointments)
          }
      )
    },
  }
})
