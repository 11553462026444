<template>
<div v-if="hasData">
<large-menubar title="Mein Profil" :parent-title="parentTitle" bar-height="small"></large-menubar>
<div class="profile-header">
<div class="profile-name">
{{customer.vorname}} {{customer.nachname}}
</div>
<svg class="profile-image">
<g>
<ellipse fill="rgba(133,139,148,1)" id="Ellipse_1" rx="40" ry="40" cx="40" cy="40">
</ellipse>
<text x="50%" y="50%" text-anchor="middle" class="initials">
{{customer.vorname.substr(0,1).toUpperCase()}}{{customer.nachname.substr(0,1).toUpperCase()}}
</text>
</g>
</svg>
</div>

<group>
<cell title="Mail">{{customer.email}}</cell>
<cell title="Handy">{{customer.mobile}}</cell>
<cell v-for="address in customerAddresses" :key="address.customer_address_id" :title="addressName(address)" class="oversize">
<div>{{address.firstname || customer.vorname}} {{address.lastname || customer.nachname}}</div>
<div v-if="address.company">{{address.company}}</div>
<div v-if="address.street">{{address.street}}</div>
<div v-if="address.po_box">{{address.po_box}}</div>
<div>{{address.zip}} {{address.city}}</div>
</cell>
</group>
</div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import Cell from 'vux/src/components/cell/index.vue'
import Group from 'vux/src/components/group/index.vue'

import LargeMenubar from '../components/LargeMenubar'

export default {
components: {
LargeMenubar,
Group,
Cell
},
data () {
return {




}
},
computed: {
parentTitle: (t) => 'Auftrag #' + t.order.order_id,
...mapState({
hasData: 'hasData',
order: 'order',
customer: 'customer',
customerAddresses: 'addresses'

}),
...mapGetters([
'customerName'

])
},
filters: {
},
methods: {
addressName (address) {
let typemap = {
'default': 'Standard',
'business': 'Geschäft'
}
return address.address_name || typemap[address.type] || 'Adresse'
}
},
mounted () {
},
created () {

}
}
</script>

<style lang="scss">

.profile-header {
padding: 10px 0 10px;
display: grid;
grid-template-rows: auto 34px;
grid-template-columns: 1fr 1fr 1fr;
grid-row-gap: 15px;

.profile-name {
white-space: nowrap;
line-height: 34px;
text-align: center;
font-family: -apple-system, "SF Pro Display",
BlinkMacSystemFont,
"Segoe UI",
Roboto,
Helvetica,
Arial,
sans-serif,
"Apple Color Emoji",  /* Emojis*/
"Segoe UI Emoji", /* Emojis*/
"Segoe UI Symbol"; /* Emojis*/;
font-style: normal;
font-weight: normal;
font-size: 28px;
color: #000000;
grid-column: 2;
grid-row: 2;
}

.profile-image {
width: 80px;
height: 80px;
margin: 0 auto;
grid-column: 2;
grid-row: 1;

.initials {
white-space: nowrap;
font-family: -apple-system, "SF Pro Text",
BlinkMacSystemFont,
"Segoe UI",
Roboto,
Helvetica,
Arial,
sans-serif,
"Apple Color Emoji",  /* Emojis*/
"Segoe UI Emoji", /* Emojis*/
"Segoe UI Symbol"; /* Emojis*/;
font-style: normal;
font-weight: normal;
font-size: 28px;
fill: #ffffff;
dominant-baseline: central;
}
}
}
</style>
